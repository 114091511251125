//compile variable sass disini

$white:    #fff;
$gray-100: #f8f9ff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-400: #e6e7f1;
$gray-500: #dfe0ea;
$gray-600: #d1d2db;
$gray-700: #a6a6b9;
$gray-800: #7a7b97;
$gray-900: #3b3e66;
$black: #070919;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
                (
                        "100": $gray-200,
                        "200": $gray-300,
                        "300": $gray-400,
                        "400": $gray-500,
                        "500": $gray-600,
                        "600": $gray-700,
                        "700": $gray-800,
                        "800": $gray-900,
                        "900": $black
                ),
                $grays
);

$first:   #3c44b1;
$second:  $black;
$blue:    #4191ff;
$indigo:  #7420ff;
$purple:  #793de6;
$pink:    #fc26a4;
$red:     #f83245;
$orange: #f4772e;
$yellow:  #ffc926;
$green:   #1bc943;
$teal:    #18e1a5;
$cyan: #11c5db;

$colors: ();
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "purple":     $purple,
                        "pink":       $pink,
                        "red":        $red,
                        "orange":     $orange,
                        "yellow":     $yellow,
                        "green":      $green,
                        "teal":       $teal,
                        "cyan":       $cyan,
                        "white":      $white,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);

$primary:       $first;
$secondary:     $gray-100;
$success:       $green;
$info:          $cyan;
$warning:       $orange;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-800;
$blue:          $blue;

$facebook:      #3b5999;
$discord:         #7189da;
$twitter:       #1da1f2;
$google:        #dd4b39;
$instagram:     #e4405f;
$pinterest:     #bd081c;
$youtube:       #cd201f;
$slack:         #3aaf85;
$dribbble:      #ea4c89;
$github:        #222222;

$theme-colors: ();
$theme-colors: map-merge(
    (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "gray":       $gray-100,
        "dark":       $dark,
        "first":      $blue,
        "second":     $second,
        "blue":       $blue,
    ),
    $theme-colors
);

$brand-colors: ();
$brand-colors: map-merge(
                (
                        "facebook":      $facebook,
"discord":      $discord,
                        "twitter":       $twitter,
                        "google":        $google,
                        "instagram":     $instagram,
                        "pinterest":     $pinterest,
                        "youtube":       $youtube,
                        "slack":         $slack,
                        "dribbble":      $dribbble,
                        "github":        $github,
                ),
                $brand-colors
);

// border radius

$border-radius:               .65rem;
$border-radius-lg:            .75rem;
$border-radius-sm:            .29rem;
$border-radius-xs:            .2rem;

// spacer

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);

// transition

$transition-base:             all .2s ease-in-out;
$transition-fade:             opacity .15s linear;
$transition-collapse:         height .35s ease;

// font weight

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;

// links

$link-color:                              $gray-900;
$link-decoration:                         none;
$link-hover-color:                        $blue;
$link-hover-decoration:                   none;

// buttons

$btn-link-disabled-color:     $gray-600;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;