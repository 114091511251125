.text-primary-custom{
    color: #6f42c1 !important;
    font-weight: bold !important;
}

.btn-primary {
    background-color: #793de6 !important;
    border-color: transparent !important;
    box-shadow: none !important;
}

.btn-filter {
    background-color: white !important;
    color: black !important;
}