@import "../../scss/variables";

.custom-dropdown-menu-left{
    right: 40px;
    top: 1px;
}

.btn-outline-custom {
    border: 1px solid $primary !important;
    color: $primary !important;
    background-color: transparent !important;

    &:focus-visible,&:hover {
        background-color: $primary !important;
        color: white !important;
    }
}

.dropdown-menu-4xl {
    width: 40rem !important;
    padding: 1rem 2rem 1.5rem !important;
}

@media only screen and (max-width: 763px) {
    .dropdown-menu-4xl {
        width: 20rem !important;
        padding: 1rem 2rem 1.5rem !important;
    }
}